import React, { Component } from "react"
import Utils from "../../../utils/node.utils"
import CartButton from "../../Shared/CartButton/CartButton"
import WishlistButton from "../../Shared/WishlistButton/WishlistButton"
import Form from "../Form/Form"
import { store as tStore } from "../../Store/Store"
import axios from "axios"

export default class Description extends Component {
  state = {
    selectedVariations: {},
    formDisabled: { Description: true },
  }

  componentDidMount() {
    const { product } = this.props

    this.unsubscribe = tStore.subscribe(this.storeUpdate)
    this.storeUpdate()

    if (!product.variations || product.variations.length == 0) {
      this.setState({
        formDisabled: {},
      })
    }
  }

  componentWillUnmount() {
    Utils.CancelToken(this.cancelToken)
    Utils.Unsubscribe(this.unsubscribe)
  }

  getNew = () => {
    const { contentMap } = this.props

    if (!this.props.new) {
      return null
    }
    return <div className="ribbon ribbon-info">{contentMap.new.value}</div>
  }

  isOutOfStock = () => {
    const { product } = this.props
    if (product.data.quantity === 0) {
      return true
    }
    return false
  }

  getOutOfStock = () => {
    const { contentMap } = this.props

    if (!this.isOutOfStock()) {
      return null
    }
    return (
      <div className="mb-4 text-muted">{contentMap.out_of_stock.value}</div>
    )
  }

  cartDisabled = () => {
    const { formDisabled } = this.state

    for (let key in formDisabled) {
      if (formDisabled[key] === true) {
        return true
      }
    }

    return false
  }

  getCartButton = () => {
    const { product, commonContentMap, language } = this.props
    const { selectedVariations } = this.state
    if (this.isOutOfStock()) {
      return null
    }
    return (
      <CartButton
        product={product}
        variations={selectedVariations}
        language={language}
        commonContentMap={commonContentMap}
        disabled={this.cartDisabled()}
      />
    )
  }

  getWishListButton = () => {
    const { product, commonContentMap, language } = this.props
    let margin

    if (!this.isOutOfStock()) {
      margin = "ml-2"
    }

    return (
      <WishlistButton
        id={product.id}
        margin={margin}
        language={language}
        commonContentMap={commonContentMap}
      />
    )
  }

  selectVariation = (variationID, optionID) => {
    const { selectedVariations } = this.state

    selectedVariations[variationID] = optionID
    this.setState(
      {
        selectedVariations: selectedVariations,
      },
      this.fetchProduct
    )
  }

  setCartDisabled = (value, comp) => {
    const { formDisabled } = this.state

    if (formDisabled["Description"]) {
      delete formDisabled["Description"]
    }

    formDisabled[comp] = value
    this.setState({
      formDisabled: formDisabled,
    })
  }

  getPrice = () => {
    const { product } = this.props
    return Utils.GetDisplayPriceHor(product.data.price)
  }

  reduceCategoriesToString = (categoryString, category) => {
    return categoryString.concat(" ", category.name)
  }

  getCategoryString = () => {
    const { product } = this.props
    return product.categories.reduce(this.reduceCategoriesToString, "")
  }

  fetchProduct = () => {
    const { product } = this.props
    const { t, selectedVariations } = this.state

    if (!t) {
      return
    }

    if (this.loading) {
      return
    }

    // Set quantity to 1 for check, if the product has variations
    // Otherwise the first variation selection can lock the product
    if (product.data.quantity === 0) {
      if (Object.keys(selectedVariations).length === 0) {
        this.loaded = true
        return
      }
      product.data.quantity = 1
    }

    this.loading = true
    this.cancelToken = axios.CancelToken.source()
    axios
      .post(
        "/enjocommerce/v1/check/products",
        Utils.CreateQuantityCheckBody(
          product.id,
          selectedVariations,
          product.data.quantity
        ),
        Utils.RequestHeaders(t, this.cancelToken)
      )
      .then(data => {
        this.loading = false
        this.loaded = true

        product.data = data.data.products[0].data

        this.setState({
          product: product,
        })
      })
      .catch(err => {
        this.loading = false
        if (err.response) {
          if (err.response.status == 400) {
            console.warn(
              "Bad request fetching product: ",
              err.response.data.message
            )
            this.setCartDisabled(true, "400")
            return
          }
        }
        Utils.LogAxiosError(err)
      })
  }

  storeUpdate = () => {
    const storeState = tStore.getState()
    if (!this.loaded) {
      this.setState(
        {
          t: storeState.t,
        },
        this.fetchProduct
      )
    } else {
      this.setState({
        t: storeState.t,
      })
    }
  }

  render() {
    const { product, contentMap, language } = this.props

    return (
      <div>
        <div className="mb-4 text-xl text-capitalize">{product.name}</div>
        <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between mb-4">
          <ul className="list-inline mb-2 mb-sm-0">
            <li className="list-inline-item h4 font-weight-light mb-0">
              {this.getPrice()}
            </li>
          </ul>
        </div>
        {this.getOutOfStock()}
        <div className="mb-4">
          {this.getCartButton()}
          {this.getWishListButton()}
        </div>
        <div>
          <Form
            product={product}
            language={language}
            selectVariation={this.selectVariation}
            contentMap={contentMap}
            setCartDisabled={this.setCartDisabled}
          />
        </div>
        <ul className="list-unstyled mt-4">
          <li className="mb-3">
            <i className="fas fa-truck mr-1 product-icon-min-width" />
            {contentMap.item_shipping.value}
          </li>
          <li className="mb-3">
            <i className="fas fa-gift mr-1 product-icon-min-width" />
            {contentMap.item_free_shipping.value}
          </li>
          <li className="mb-3">
            <i className="fas fa-clock mr-1 product-icon-min-width" />
            {contentMap.item_secure_payment.value}
          </li>
          <li className="mb-3">
            <i className="fas fa-envelope mr-1 product-icon-min-width" />
            {contentMap.item_track_trace.value}
          </li>
        </ul>
      </div>
    )
  }
}
