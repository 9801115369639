import React, { Component } from "react"
import Layout from "../components/Layout/Layout/Layout"
import Cover from "../components/ProductDetail/Cover/Cover"
import Description from "../components/ProductDetail/Description/Description"
import Details from "../components/ProductDetail/Details/Details"
import SEO from "../components/SEO/SEO"
import Utils from "../utils/node.utils"
import Breadcrumb from "../components/Shared/Breadcrumb/Breadcrumb"

const bcs = {
  shop: true,
  jewelry: true,
  accessories: true,
}

export default class Post extends Component {
  state = {}

  constructor(props) {
    super(props)
    const product = this.props.pageContext.edge
    if (typeof window !== "undefined") {
      Utils.NavigateToBrowserLanguage(
        props.pageContext.language,
        "/shop/" + product.slug,
        props.location.state,
        props.location.search
      )
    }
  }

  readBreadCrumbFromURI = () => {
    const search = this.props.location.search
    let breadCrumb = ""

    if (search.includes("?breadcrumb=")) {
      breadCrumb = search.replace("?breadcrumb=", "")
    }

    if (!(breadCrumb in bcs)) {
      breadCrumb = "shop"
    }
    return breadCrumb
  }

  render() {
    const {
      props: {
        location: { origin },
        pageContext: { language, edge, shopContent, commonContent },
      },
    } = this

    const contentMap = Utils.ContentListToMap(shopContent.item.content)
    const commonContentMap = Utils.ContentListToMap(commonContent.item.content)
    const product = edge
    const selfLink = Utils.GetLanguageLink(language, "/shop/" + product.slug)
    const breadCrumb = this.readBreadCrumbFromURI()
    const selfKey = "__" + product.slug
    const breadCrumbs = ["menu_home", "menu_" + breadCrumb, selfKey]

    const name = Utils.GetContentKeyValue(product.content, "name")
    const description = Utils.GetContentKeyValue(product.content, "description")
    const details = Utils.GetContentKeyValue(product.content, "details")
    const thumbnail = Utils.GetContentKeyValue(product.content, "thumbnails")
    const images = Utils.GetContentKeyValues(product.content, "images")
    const quantity = Utils.GetProductQuantity(product)
    const fullLink = selfLink + "?breadcrumb=" + breadCrumb

    commonContentMap[selfKey] = {
      key: selfKey,
      value: `[${name}](${fullLink})`,
    }

    if (product.data) {
      product.data.quantity = quantity
    } else {
      product.data = { quantity: quantity }
    }

    const cProduct = {
      name: name,
      description: description,
      thumbnail: thumbnail,
      images: images,
      details: details,
      ...product,
    }

    return (
      <div>
        <Layout
          origin={origin}
          originPage={"shop/" + product.slug}
          language={language}
          commonContentMap={commonContentMap}
        >
          <SEO
            title={name}
            language={language}
            keywords={Utils.GetKeywordsFromTags(product.tags)}
            description={description}
            image={thumbnail}
            selfLink={"shop/" + product.slug}
          />
          <div className="d-block d-lg-none text-center">
            <Breadcrumb
              breadcrumbs={breadCrumbs}
              commonContentMap={commonContentMap}
              position="center"
            />
          </div>
          <div className="container">
            <div className="hero-content pb-0 text-center"></div>
          </div>
          <section className="">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 py-3 order-1 order-lg-1">
                  <Cover product={cProduct} />
                </div>
                <div className="d-flex col-lg-6 col-xl-6 pl-lg-5 mb-5 order-2 order-lg-2">
                  <div>
                    <div className="d-none d-lg-block">
                      <Breadcrumb
                        breadcrumbs={breadCrumbs}
                        commonContentMap={commonContentMap}
                        position="start"
                      />
                    </div>
                    <Description
                      product={cProduct}
                      language={language}
                      contentMap={contentMap}
                      commonContentMap={commonContentMap}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Details
            product={cProduct}
            language={language}
            contentMap={contentMap}
          />
        </Layout>
      </div>
    )
  }
}
