import React, { Component } from "react"
import Utils from "../../../utils/node.utils"

export default class WishlistButton extends Component {
  state = {
    css: "btn btn-outline-dark btn-product-left",
    active: false,
  }

  componentDidMount() {
    const existingWishlist = JSON.parse(localStorage.getItem("wishlist-items"))

    if (existingWishlist && existingWishlist.length) {
      if (existingWishlist.filter(this.inWishlist).length > 0) {
        this.setState({
          css: "btn btn-dark product-hover-overlay-buttons btn-product-left",
          active: true,
        })
      }
    }
  }

  inWishlist = item => {
    return item.id == this.props.id
  }

  modWishlist = () => {
    const {
      state,
      props: { id, commonContentMap },
    } = this

    if (state.active) {
      Utils.RemoveFromCart(
        { id: id },
        {},
        "wishlist-items",
        true,
        commonContentMap
      )
      this.setState({
        css: "btn btn-outline-dark btn-product-left",
        active: false,
      })
    } else {
      Utils.AddToCart({ id: id }, {}, "wishlist-items", true, commonContentMap)
      this.setState({
        css: "btn btn-dark btn-product-left",
        active: true,
      })
    }
  }

  getTextCss = () => {
    const { showText } = this.props
    if (showText) {
      return "d-block d-lg-none"
    }
    return "d-none"
  }

  getIconCss = () => {
    const { showText } = this.props
    if (showText) {
      return "d-none d-lg-block"
    }
    return ""
  }

  render() {
    const {
      modWishlist,
      props: { margin, commonContentMap },
    } = this
    const { active } = this.state

    let className = this.state.css
    if (margin) {
      className += " " + margin
    }

    const title = active
      ? commonContentMap.button_remove_wishlist.value
      : commonContentMap.button_add_wishlist.value

    return (
      <div
        className={className}
        title={title}
        onClick={modWishlist}
        aria-label={title}
      >
        <div className={this.getTextCss()}>{title}</div>
        <div className={this.getIconCss()}>
          <i className="fa fa-heart" />
        </div>
      </div>
    )
  }
}
