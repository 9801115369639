import React, { Component } from "react"
import Tabs from "./Tabs"

export default class Details extends Component {
  getDetails(product) {
    if (product.weight) {
      return product.details + " | " + product.weight + "g"
    }
    return product.details
  }

  render() {
    const { product, contentMap } = this.props
    return (
      <section className="mt-0">
        <Tabs>
          <div label={contentMap.description.value}>{product.description}</div>
          <div label={contentMap.details.value}>{this.getDetails(product)}</div>
        </Tabs>
      </section>
    )
  }
}
