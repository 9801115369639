import React, { Component } from "react"
import Utils from "../../../utils/node.utils"
import { store as tStore } from "../../Store/Store"

export default class CartButton extends Component {
  state = {}

  componentDidMount() {
    this.unsubscribe = tStore.subscribe(this.storeUpdate)
    this.storeUpdate()
  }

  componentWillUnmount() {
    Utils.CancelToken(this.cancelToken)
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  storeUpdate = () => {
    const storeState = tStore.getState()
    this.setState({
      t: storeState.t,
    })
  }

  addToCart = () => {
    const { product, variations, commonContentMap, disabled } = this.props
    const { t } = this.state

    if (!t) {
      console.warn("Cannot fetch products, not authenticated")
      return
    }

    if (disabled) {
      return
    }

    this.cancelToken = Utils.AddToCart(
      product,
      variations,
      "cart-items",
      false,
      commonContentMap,
      undefined,
      t
    )
  }

  render() {
    const {
      addToCart,
      props: { commonContentMap, disabled },
    } = this
    const { active } = this.state

    let css = "btn btn-outline-dark btn-product-left"

    if (disabled) {
      css += " disabled"
    }

    const title = active
      ? commonContentMap.button_remove_cart.value
      : commonContentMap.button_add_cart.value

    return (
      <div title={title} className={css} onClick={addToCart} aria-label={title}>
        <i className="fa fa-cart-plus"></i>
      </div>
    )
  }
}
