import React, { Component } from "react"
import DefaultImage from "../../../images/default.png"
import CarouselProduct from "../../Shared/Carousel/CarouselProduct"

export default class Cover extends Component {
  getSale = () => {
    const { product } = this.props
    if (product.discount_price) {
      return <div className="ribbon ribbon-pink">Sale</div>
    }
  }

  render() {
    const { product } = this.props
    let carouselItems = [
      {
        src: DefaultImage,
        altText: "Default",
        caption: "Default",
      },
    ]

    if (product.images) {
      if (product.images.length !== 0) {
        carouselItems.length = 0
        carouselItems = product.images.map((image, index) => {
          return {
            key: index + 1,
            src: image,
            altText: product.description,
            caption: product.title,
          }
        })
      }
    }
    return (
      <div>
        {this.getSale()}
        <CarouselProduct items={carouselItems} />
      </div>
    )
  }
}
